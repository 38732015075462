<template>
  <v-container id="user-profile" fluid tag="section">

    <el-skeleton :rows="15" animated v-if="loading" />
    <v-row justify="center" v-else>
      <v-col cols="12" md="12">
        <base-material-card color="primary">
          <template v-slot:heading >
            <div class="subtitle-1 font-weight-light">
              Toutes mes informations
            </div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                
                <div class="display-2 font-weight-light mt-2"> Mes Informations personnel</div>
                <v-col cols="12" md="4" >
                  <v-text-field class="purple-input" v-model="users.name" label="Nom"/>
                </v-col>

                <v-col cols="12" md="4" >
                  <v-text-field class="purple-input" v-model="users.surname" label="Prénom"/>
                </v-col>

                <v-col cols="12" md="4" >
                  <div class="subtitle-10 font-weight-light"> Date de naissance</div>
                  <el-date-picker v-model="users.date_naiss" type="date" placeholder="Modifier votre date de naissance"></el-date-picker>
                </v-col>

                <v-col cols="12" md="4" >
                  <v-text-field class="purple-input" v-model="users.phone"  label="Numéro personnel"/>
                </v-col>

                <v-col cols="12" md="4" >
                  <v-text-field class="purple-input" v-model="users.email"  label="Email"/>
                </v-col>

                <el-divider class="mt-2"></el-divider>

                <div class="display-2 font-weight-light mt-2 mb-2">Mes Informations profésionnel</div>
                
                <v-col cols="12" md="4" >
                  <v-text-field class="purple-input" v-model="users.username" label="Identifiant de Connexion"/>
                </v-col>

                <!-- <v-col cols="12" md="4" >
                  <v-text-field class="purple-input" :value="users.password"  label="Mot de passe"/>
                </v-col> -->

                <v-col cols="12" md="4" >
                  <v-text-field class="purple-input"  v-model="users.phone_p"  label="Numéro professionnel"/>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field class="purple-input" v-model="users.function" label="Poste" disabled/>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field class="purple-input" v-model="users.ville" label="Ville" disabled/>
                </v-col>

                <v-col cols="12" md="4" >
                  <v-text-field class="purple-input" v-model="users.service" label="Service" disabled/>
                </v-col>

                <el-divider></el-divider>
                <v-col cols="12" md="12" >
                  <div class="display-2 font-weight-light m-2">
                      Mes accèss sur l'application :
                      <el-tag effect="dark" class="m-2" v-for="item in menu" :key="item">
                      <span v-if="item == 'report'">Rapport</span> 
                      <span v-if="item == 'statistique'">Statistique</span> 
                      <span v-if="item == 'administration'">Administration</span> 
                      <span v-if="item == 'setting'">Parametre</span> 
                      </el-tag>
                  </div>
                 
                </v-col>

                <!-- <v-col cols="12">
                  <v-textarea
                    class="purple-input"
                    label="About Me"
                    value="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                  />
                </v-col> -->

                <v-col cols="12" class="text-right" >
                  <v-btn color="primary" class="mr-0" @click="showDialogSave" style="border-radius:0px;">
                    Faire la mise a jour
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

    import NProgress from 'nprogress';
    import 'nprogress/nprogress.css';
    import axios from 'axios';
    import moment from 'moment';

    export default {
      //

      data() {

        return {
            loading: true,
            users: [],
            menu: [],
        };

      },
      methods: {
      
        async loadFilter()
        {
            NProgress.start();

            try {
              
                const response = await axios.get('auth/user',);

                this.users = response.data.user;
                this.users['hold_password'] = this.users.password;
                this.menu = response.data.menu.menu.menu;
                this.loading = false;
                console.log(this.users);

            } 
            catch (error) {

                this.loading = false;
                this.$notify({
                    type: 'error',
                    title: "Echec de recuperation"
                });
            }

            NProgress.done();

        },

        showDialogSave(){
            this.$swal({
            title: 'Confirmer ?',
            text: "Vous êtes sur le point de faire la mise à jour de votre compte",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#EE9D06',
            confirmButtonText: 'Oui'
            }).then((result) => {
                if (result.value) {
                    this.UpdateUser();
                }
            });
        },

        async UpdateUser()
        {
            NProgress.start();

            try {

              this.users.date_naiss = moment(new Date(this.users.date_naiss)).startOf('day').format('YYYY-MM-DD');
              const response = await axios.post('v1/xperf/setting/user/editProfile', this.users);
              this.$swal(
                        'Enregistrer !',
                        'Votre profil a été mis à jour avec succès',
                        'success'
                    );
            } 
            catch (error) {

                this.$swal(
                      'Enregistrer !',
                      'Echec de mis à jour !',
                      'error'
                  );
            }

            NProgress.done();

        },

      },

      created () {
        this.loadFilter();
      }

    }
</script>
